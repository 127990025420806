<template>
  <div style="height: 100%">
    <div class="checklist-edit">
      <div class="step-box">
        <head-layout :show-icon="false" head-title="处理进度"></head-layout>
        <el-steps
          direction="vertical"
          :active="2"
          style="margin-top: 30px; align-items: center; height: 50%"
        >
          <el-step title="上报"></el-step>
          <el-step title="评估"></el-step>
          <el-step title="整改"></el-step>
          <el-step title="验收"></el-step>
        </el-steps>
      </div>
      <div class="form-box">
        <div class="">
          <head-layout
            :head-title="headTitle"
            :head-btn-options="btnOptions"
            @head-cancel="headCancel"
          ></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form
                  ref="dataForm"
                  :model="dataForm"
                  label-width="150px"
                  disabled
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="隐患编号" prop="hdCode">
                        <el-input
                          v-model="dataForm.hdCode"
                          disabled
                          placeholder="请输入隐患编号"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="隐患地点" prop="prjName">
                        <el-input
                          v-model="dataForm.location"
                          :title="dataForm.location"
                          placeholder="请填写隐患地点"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="项目名称" prop="organizationName">
                        <el-input v-model="dataForm.organizationName" ref="organizationName" placeholder="请选择被通知项目" :title="dataForm.organizationName">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="项目区域" prop="area">
                        <el-input v-model="dataForm.area" ref="areaName" placeholder="请选择项目区域" :title="dataForm.area" >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="报告人" prop="reportUserName">
                        <el-input
                          v-model="dataForm.reportUserName"
                          placeholder="请填写报告人"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告部门" prop="reportDeptName">
                        <el-input
                          v-model="dataForm.reportDeptName"
                          :title="dataForm.reportDeptName"
                          placeholder="请填写报告部门"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告时间" prop="reportDate">
                        <el-date-picker
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="dataForm.reportDate"
                          placeholder="请选择报告时间"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患描述" prop="hdDesc">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2 }"
                          show-word-limit
                          v-model="dataForm.hdDesc"
                          placeholder="请填写隐患描述"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="隐患来源" prop="sourceType">
                        <avue-select
                          :props="{
                            label: 'dictValue',
                            value: 'dictKey',
                          }"
                          v-model="dataForm.sourceType"
                          placeholder="请选择隐患来源"
                          type="tree"
                          :dic="HD_SOURCE_TYPE"
                        ></avue-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患现场" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData"
                            :key="item.id"
                            class="img-content"
                            @click="showFile(item)"
                          >
                            <img :src="item.thumbnailLink" alt="" />
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hide != 'hide'" class="">
          <head-layout
            head-title="隐患评估"
            :head-btn-options="headBtnOptions"
            @head-saveBack="headSave(true)"
            @head-save="headSave(false)"
          ></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form
                  ref="dataForm1"
                  :model="dataForm1"
                  label-width="150px"
                  :disabled="formType == 'view'"
                  :rules="formType == 'view' ? {} : rules"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="隐患核实" prop="passFlag">
                        <el-radio v-model="dataForm1.passFlag" label="y"
                          >核实通过</el-radio
                        >
                        <el-radio v-model="dataForm1.passFlag" label="n"
                          >核实不通过</el-radio
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患等级" prop="hdLevel">
                        <avue-select
                          :props="{
                            label: 'dictValue',
                            value: 'dictKey',
                          }"
                          v-model="dataForm1.hdLevel"
                          placeholder="请选择隐患等级"
                          type="tree"
                          :dic="hd_level"
                        ></avue-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患类型" prop="hdType">
                        <avue-select
                          :props="{ label: 'dictValue', value: 'dictKey' }"
                          v-model="dataForm1.hdType"
                          placeholder="请选择隐患类型"
                          type="tree"
                          :dic="hd_type"
                        ></avue-select>
                      </el-form-item>
                    </el-col>
                    <template>
                      <el-col :span="8" v-if="dataForm1.hdType == '1'">
                        <el-form-item label="隐患因素" prop="hdFactors">
                          <avue-select
                            :props="{ label: 'dictValue', value: 'dictKey' }"
                            v-model="dataForm1.hdFactors"
                            placeholder="请选择隐患因素"
                            type="tree"
                            :dic="hd_factors"
                          ></avue-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="dataForm1.hdType == '1'">
                        <el-form-item label="隐患后果" prop="hdResult">
                          <avue-select
                            :props="{ label: 'dictValue', value: 'dictKey' }"
                            v-model="dataForm1.hdResult"
                            placeholder="请选择隐患后果"
                            type="tree"
                            :dic="hd_result"
                          ></avue-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="dataForm1.hdType == '2'">
                        <el-form-item label="违章类型" prop="hdBreakRules">
                          <avue-select
                            :props="{ label: 'dictValue', value: 'dictKey' }"
                            v-model="dataForm1.hdBreakRules"
                            placeholder="请选择违章类型"
                            type="tree"
                            :dic="hd_break_rules"
                          ></avue-select>
                        </el-form-item>
                      </el-col>
                    </template>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改单位" prop="rectifyDeptName">
                        <div class="flex-container">
                          <el-select v-model="dataForm1.rectifyDeptId" placeholder="请选择整改单位" @change="changeCompany">
                            <el-option v-for="item in rectifyDeptData" :key="item.dutyUnit" :value="item.dutyUnit" :label="item.dutyUnitName"></el-option>
                          </el-select>
                          <el-button size="mini" type="primary" class="hdDesc-mar" @click="handleChangeOrg">选择组织&人员</el-button>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改人" prop="rectifyUserName">
                        <el-select v-model="dataForm1.rectifyUser" filterable placeholder="请先选择整改单位" @change="changeThreeUser">
                            <el-option v-for="item in rectifyDeptData" :key="item.dutyUser" :value="item.dutyUser" :label="item.dutyUserName" ></el-option>
                         </el-select>
                      </el-form-item>
                    </el-col>
                    
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改期限" prop="rectifyLimitDate">
                        <el-date-picker
                          type="date"
                          :picker-options="pickerOptionsStart"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="dataForm1.rectifyLimitDate"
                          placeholder="请选择整改期限"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col
                      :span="24"
                      v-if="dataForm1.passFlag == 'y'"
                      key="rectifyRequest"
                    >
                      <el-form-item label="整改要求" prop="rectifyRequest">
                        <el-input
                          type="textarea"
                          maxlength="500"
                          :autosize="{ minRows: 2 }"
                          show-word-limit
                          v-model="dataForm1.rectifyRequest"
                          placeholder="请填写整改要求"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="24"
                      v-if="dataForm1.passFlag == 'n'"
                      key="remark"
                    >
                      <el-form-item label="备注" prop="remark">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2 }"
                          show-word-limit
                          v-model="dataForm1.remark"
                          placeholder="请填写备注"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="部门选择" :visible.sync="respDeptNameShow" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="respDeptNameFun"></DeptDialog>
    </el-dialog>
    <el-dialog
      title="人员选择"
      :visible.sync="respUserShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="respUserFun"
      ></UserDetpDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="专业负责人选择" :visible.sync="userDialog" width="80%">
      <rectifyDataOrg ref="rectifyDataOrg" @callback="orgUserCallback" :prjId=dataForm.organizationId />
    </el-dialog>
  </div>
</template>
<script>
import * as API from "@/api/riskitemView/evaluate";
import { detail } from "@/api/riskitemView/checklist";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import { dateFormat } from "@/util/date";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import snows from "@/util/snows";
import {prjAreaUserPage} from '@/api/safe/projectArea.js';
import { mapGetters } from 'vuex';
import { prjthirduserPage, prjthirdcompanyPage, prjthirduserList } from "@/api/safe/itemList";
import rectifyDataOrg from '../checklist/rectifyDataOrg.vue'

export default {
  components: {
    DeptDialog,
    UserDetpDialog,
    rectifyDataOrg,
  },
  data() {
    return {
      hide: "",
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      fileList: [],
      treeData: [],
      treeData1: [],
      treeData2: [],
      HD_SOURCE_TYPE: [],
      hd_level: [],
      hd_type: [],
      hd_factors: [],
      hd_result: [],
      hd_break_rules: [],
      formType: "",
      fileUrl: "",
      headTitle: "",
      dataDeptType: "",
      dataType: "",
      mapBox: false,
      respDeptNameShow: false,
      showFileDialog: false,
      respUserShow: false,
      mapdata: "",
      dataForm1: {
        passFlag: "y",
        respUserName: "",
        respDeptName: "",
        rectifyUserName: "",
        rectifyDeptName: "",
        acceptUserName: "",
        acceptDeptName: "",
        hdType: "",
        rectifyLimitDate: "",
        rectifyUser: "",
        acceptDept: "",
        assessmentTime: dateFormat(new Date()),
        rectifyPlanFlag: "",
        rectifyRequest: "",
        respUser: "",
        hdLevel: "",
        respDeptId: "",
      },
      dataForm: {
        id: "",
        hdCode: "",
        hdDesc: "",
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      rules: {
        rectifyUserName: [
          {
            required: true,
            message: "请选择整改人",
            trigger: "change",
          },
        ],
        rectifyDeptName: [
          {
            required: true,
            message: "请选择整改部门",
            trigger: "change",
          },
        ],
        respUserName: [
          {
            required: true,
            message: "请选择责任人",
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
        rectifyRequest: [
          {
            required: true,
            message: "请输入整改要求",
            trigger: "blur",
          },
        ],
        hdLevel: [
          {
            required: true,
            message: "请选择隐患等级",
            trigger: "change",
          },
        ],
        acceptDept: [
          {
            required: true,
            message: "请选择验收部门",
            trigger: "change",
          },
        ],
        rectifyLimitDate: [
          {
            required: true,
            message: "请选择整改期限",
            trigger: "blur",
          },
        ],
        rectifyDeptId: [
          {
            required: true,
            message: "请选择整改部门",
            trigger: "change",
          },
        ],
        rectifyUser: [
          {
            required: true,
            message: "请选择整改人",
            trigger: "change",
          },
        ],
        hdDesc: [
          {
            required: true,
            message: "请输入项目描述",
            trigger: "blur",
          },
        ],
        assessmentTime: [
          {
            required: true,
            message: "请选择评估时间",
            trigger: "change",
          },
        ],
        hdType: [
          {
            required: true,
            message: "请选择隐患类型",
            trigger: "change",
          },
        ],
        hdFactors: [
          {
            required: true,
            message: "请选择隐患因素",
            trigger: "change",
          },
        ],
        hdResult: [
          {
            required: true,
            message: "请选择隐患后果",
            trigger: "change",
          },
        ],
        hdBreakRules: [
          {
            required: true,
            message: "请选择违章类型",
            trigger: "change",
          },
        ],
      },
      selectionList: [],
      userDialog: false,
      // areaUserType: false, // 专业责任人来源，true: 区域配置人员, false: 系统人员
      // postDicCode: '',
      rectifyDeptData: [], // 相关方组织列表
      // threeUserData: [], // 对应人员信息
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    btnOptions() {
      return [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        },
      ];
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
        result.push({
          label: '暂存',
          emit: "head-save",
          type: "button",
          btnOptType: "primary",
        });
      }
      return result;
    },
  },
  mounted() {
    let type = this.$route.query.type;
    this.hide = this.$route.query.hide;
    this.formType = type;
    if (type == "edit") {
      this.headTitle = "隐患信息";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.headTitle = "隐患信息";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    }
    this.getCode("HD_SOURCE_TYPE");
    this.getCode("hd_level");
    this.getCode("hd_type");
    this.getCode("hd_factors");
    this.getCode("hd_result");
    this.getCode("hd_break_rules");
  },
  methods: {
    handlePreview(file) {
      snows.downloadFile1(file.link, file.name);
    },
    respDeptNameFun(row) {
      if (this.dataDeptType == "respDeptName") {
        // this.$set(this.dataForm1, "respDeptName", row.deptName);
        // this.$set(this.dataForm1, "rectifyDeptName", row.deptName);
        // this.$set(this.dataForm1, "rectifyDeptId", row.id);
        // this.$set(this.dataForm1, "respDeptId", row.id);
        this.$set(this.dataForm1, "respDeptName", row.defaultDeptId > 1 ? row.defaultDeptName : row.deptName);
        this.$set(this.dataForm1, "rectifyDeptName", row.defaultDeptId > 1 ? row.defaultDeptName : row.deptName);
        this.$set(this.dataForm1, "rectifyDeptId", row.defaultDeptId > 1 ? row.defaultDeptId : row.id);
        this.$set(this.dataForm1, "respDeptId",row.defaultDeptId > 1 ? row.defaultDeptId : row.id);
      } else if (this.dataDeptType == "rectifyDeptName") {
        this.$set(this.dataForm1, "rectifyDeptName", row.deptName);
        this.$set(this.dataForm1, "rectifyDeptId", row.id);
      } else {
        this.$set(this.dataForm1, "acceptDept", row.id);
        this.$set(this.dataForm1, "acceptDeptName", row.deptName);
      }
      this.$forceUpdate();
      this.respDeptNameShow = false;

      console.log("进行输出人员-------------------", row);
    },
    handleUserModeOpen(type) {
      this.dataType = type;
      this.respUserShow = true;
    },

    handleDeptShow(type) {
      this.dataDeptType = type;
      this.respDeptNameShow = true;
    },
    respUserFun(row, deptId, treeDeptName) {
      if (this.dataType == "respUser") {
        this.$set(this.dataForm1, "respUser", row.id);  // 专业负责人
        this.$set(this.dataForm1, "respUserName", row.realName);
        this.$set(this.dataForm1, "rectifyUser", row.id); // 整改人
        this.$set(this.dataForm1, "rectifyUserName", row.realName);
        this.$set(this.dataForm1, "respDeptId", deptId);  // 责任单位
        this.$set(this.dataForm1, "respDeptName", treeDeptName);
        this.$set(this.dataForm1, "rectifyDeptId", deptId); // 整改单位
        this.$set(this.dataForm1, "rectifyDeptName", treeDeptName);
      } 
      this.$forceUpdate();
      this.respUserShow = false;
      console.log("进行输出人员-------------------", row);
    },

    getCode(code) {
      API.HD_SOURCE_TYPE(code).then((res) => {
        this[code] = res.data.data;
      });
    },
    // 新增上传文件
    handleSuccess1(response, file, fileList) {
      let list = new Array();
      fileList.map((i) => {
        if (i.response) {
          list.push({
            extension: i.response.data.type,
            id: i.response.attachId,
            name: i.response.data.originalName,
            originalName: i.response.data.name,
            link: i.response.data.link,
          });
        } else {
          list.push({
            extension: i.type,
            id: i.attachId,
            originalName: i.originalName,
            link: i.link,
            name: i.name,
            url: i.url,
          });
        }
      });
      this.treeData2 = list;
    },
    // 删除上传文件
    handleRemove1(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            extension: i.response.data.type,
            id: i.response.dataattachId,
            name: i.name,
            link: i.response.data.link,
          });
        } else {
          list.push({
            extension: i.type,
            id: i.dataattachId,
            link: i.link,
            name: i.name,
            url: i.url,
          });
        }
      });
      this.treeData1 = list;
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let list = [];
      fileList.map((i) => {
        if (i.response) {
          list.push({
            extension: i.response.data.type,
            id: i.response.data.attachId,
            name: i.name,
            originalName: i.response.data.name,
            link: i.response.data.link,
            url: i.response.data.link,
          });
        } else {
          list.push({
            extension: i.type,
            id: i.attachId,
            originalName: i.originalName,
            link: i.link,
            name: i.name,
            url: i.url,
          });
        }
      });
      this.treeData1 = list;
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        fileType === ".jpg" || fileType == ".png" || fileType == ".mp4";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png .mp4 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    checkFileType1(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const fileTran = [".rar", ".zip", ".doc", ".docx", ".pdf"];
      const isJPG = fileTran.includes(fileType);

      if (!isJPG) {
        this.$message.warning("上传图片只能是 .rar  .zip .docx .pdf格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },

    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm1.validate((valid) => {
        if (valid) {
          this.$loading();
          let url = this.dataForm1.id ? API.update : API.save;
          API.save({
            ...this.dataForm1,
            hdStatus: boolean ? "unrectify" : "prepare",
            hdId: this.dataForm.id,
            assessmentUrl: JSON.stringify(this.treeData1),
            punishUrl: JSON.stringify(this.treeData2),
          })
            .then((res) => {
              if (res.data.code == 200) {
                if (boolean) {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                } else {
                  this.getPrjbasicinfoDetail();
                }
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.$loading().close();
              }
            })
            .catch((err) => {
              this.$loading().close();
            });
        }
      });
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      detail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          // this.getThreeData('company', this.dataForm.organizationId)
          if (JSON.stringify(res.data.data.hdAssessmentVO) != "{}") {
            this.dataForm1 = res.data.data.hdAssessmentVO;
          }
          if (
            this.dataForm.hdStatus == "unassess" &&
            JSON.stringify(res.data.data.hdAssessmentVO) != "{}"
          ) {
            this.dataForm1 = res.data.data.hdAssessmentVO;
          }
          if(!this.dataForm1.respUser){
            prjAreaUserPage(1,10,{prjAreaId: this.dataForm.areaId}).then((res) => {
                const data = res.data.data
                this.rectifyDeptData = data.records
                if(data.total) {
                  const userData = data.records[0]
                  this.$set(this.dataForm1, 'respUser', userData.dutyUser) // 专业负责人
                  this.$set(this.dataForm1, 'respUserName', userData.dutyUserName)
                  this.$set(this.dataForm1, 'rectifyUser', userData.dutyUser)  // 整改人
                  this.$set(this.dataForm1, 'rectifyUserName', userData.dutyUserName)
                  this.$set(this.dataForm1, 'respDeptId', userData.dutyUnit)  // 责任部门
                  this.$set(this.dataForm1, 'respDeptName', userData.dutyUnitName)
                } else if(this.dataForm1.respUser==0){
                  this.$set(this.dataForm1, 'rectifyUser', '')
                }
              })

          }
          this.dataForm1.acceptUser = this.userInfo.user_id
          this.dataForm1.acceptUserName = this.userInfo.real_name
          this.dataForm1.acceptDept = this.userInfo.dept_id
          this.dataForm1.acceptDeptName = this.userInfo.dept_name 
          this.dataForm1.passFlag = !this.dataForm1.passFlag
            ? "y"
            : this.dataForm1.passFlag;
          this.dataForm1.assessmentTime = !this.dataForm1.assessmentTime
            ? dateFormat(new Date())
            : this.dataForm1.assessmentTime;
          if (JSON.stringify(res.data.data.hdAssessmentVO) != "{}") {
            this.dataForm1.id = res.data.data.hdAssessmentVO.id;
          }
          this.treeData = JSON.parse(this.dataForm.hdUrl);
          if (res.data.data.hdAssessmentVO.assessmentUrl) {
            this.treeData1 = JSON.parse(
              res.data.data.hdAssessmentVO.assessmentUrl
            );
          }
          if (res.data.data.hdAssessmentVO.punishUrl) {
            this.treeData2 = JSON.parse(res.data.data.hdAssessmentVO.punishUrl);
          }
          // if(this.dataForm1.rectifyDeptId){
          //   this.getThreeData('rectifyUser', this.dataForm1.rectifyDeptId)
          // }
        }
      });
    },
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            extension: i.response.data.type,
            id: i.response.dataattachId,
            name: i.name,
            link: i.response.data.link,
            url: i.response.data.link,
          });
        } else {
          list.push({
            extension: i.type,
            id: i.dataattachId,
            link: i.link,
            name: i.name,
            url: i.url,
          });
        }
      });
      this.treeData2 = list;
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    // 获取相关方数据
    // getThreeData (type, val) {
    //   if(type == 'company') {
    //     prjthirdcompanyPage({prjId: val}).then((res) => {
    //       if ((res.data.code == 200)) {
    //         this.threeOrganizationData=res.data.data
    //       }
    //     });
    //   } else if (type == 'rectifyUser') {
    //     prjthirduserList({thirdFlg:1,prjId: this.dataForm.organizationId,deptId: val}).then((res) => {
    //       console.log('整改人获取',res)
    //       this.threeUserData = res.data.data;
    //     });
    //   }
    // },

    changeCompany (val) {
      const data = this.rectifyDeptData.find(v=>v.dutyUnit == val)
      this.$set(this.dataForm1, 'rectifyDeptId', val)
      this.$set(this.dataForm1, 'rectifyDeptName', data.dutyUnitName)
      this.$set(this.dataForm1, 'rectifyUser', data.dutyUser)
      this.$set(this.dataForm1, 'rectifyUserName', data.dutyUserName)
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
    },

    changeThreeUser (val) {
      const data = this.rectifyDeptData.find(v=>v.dutyUser == val)
      this.$set(this.dataForm1, 'rectifyDeptId', data.dutyUnit || '')
      this.$set(this.dataForm1, 'rectifyDeptName', data.dutyUnitName || '')
      this.$set(this.dataForm1, 'rectifyUser', val)
      this.$set(this.dataForm1, 'rectifyUserName', data.dutyUserName || '')
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
    },
    handleChangeOrg () {
      this.userDialog = true
    },
    orgUserCallback (row) {
      this.userDialog = false
      let data = {
        userId: row.userId,
        userName: row.userName,
        deptId: row.deptId,
        deptName: row.deptName,
      }
      this.pgUserInfo(data)
      // 为了方便展示，添加一条数据到下拉数据中(先删除前一条由弹窗加入的数据)
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
      this.rectifyDeptData.push({
        dutyUser: row.userId,
        dutyUserName: row.userName,
        dutyUnit: row.deptId,
        dutyUnitName: row.deptName,
        type: 'dialog'
      })
    },

    pgUserInfo (row) {
      this.$set(this.dataForm1, "respUser", row.userId); // 责任人
      this.$set(this.dataForm1, "respUserName", row.userName);
      this.$set(this.dataForm1, "rectifyUser", row.userId); // 整改人userId
      this.$set(this.dataForm1, "rectifyUserName", row.userName);
      this.$set(this.dataForm1, "respDeptId", row.deptId); // 责任部门
      this.$set(this.dataForm1, "respDeptName", row.deptName);
      this.$set(this.dataForm1, "rectifyDeptId", row.deptId); // 整改单位
      this.$set(this.dataForm1, "rectifyDeptName", row.deptName);
    }
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.step-box {
  background-color: #ffffff;
  width: 125px;
}
.form-box {
  background-color: #ffffff;
  width: calc(100% - 135px);
  height: 100%;
  overflow: scroll;
}
.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  .el-tabs {
    width: 100%;
  }

  .table-box {
    width: calc(100% - 280px);
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}
.checklist-edit {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
.content {
  display: flex;
  align-items: center;
  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;
    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
