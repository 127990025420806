var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-skeleton",
        { attrs: { loading: _vm.waiting, avatar: "", rows: 8 } },
        [
          _c("avue-affix", { attrs: { id: "avue-view", "offset-top": 114 } }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("avue-title", {
                  attrs: { value: _vm.process.processDefinitionName },
                }),
                _vm.process.status != "todo"
                  ? _c(
                      "div",
                      [
                        _vm._v("\n          主题：\n          "),
                        _c("avue-select", {
                          attrs: {
                            size: "mini",
                            clearable: false,
                            dic: _vm.themeList,
                          },
                          model: {
                            value: _vm.theme,
                            callback: function ($$v) {
                              _vm.theme = $$v
                            },
                            expression: "theme",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadFirst`),
                    name: "first",
                  },
                },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c(
                      "div",
                      { attrs: { id: "printBody" } },
                      [
                        _c(
                          "el-scrollbar",
                          [
                            _c("form-layout", {
                              ref: "formLayout",
                              attrs: {
                                column: _vm.option.column,
                                dataForm: _vm.form,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.process.status == "todo"
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c("wf-examine-form", {
                            ref: "examineForm",
                            attrs: {
                              comment: _vm.comment,
                              process: _vm.process,
                            },
                            on: {
                              "update:comment": function ($event) {
                                _vm.comment = $event
                              },
                              "user-select": _vm.handleUserSelect,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadSecond`),
                    name: "second",
                  },
                },
                [
                  _c(
                    "el-card",
                    {
                      staticStyle: { "margin-top": "5px" },
                      attrs: { shadow: "never" },
                    },
                    [_c("wf-flow", { attrs: { flow: _vm.flow } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(`cip.desk.workflow.title.indexHeadThird`),
                    name: "third",
                  },
                },
                [
                  _vm.activeName == "third"
                    ? [
                        _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c("wf-design", {
                              ref: "bpmn",
                              staticStyle: { height: "500px" },
                              attrs: { options: _vm.bpmnOption },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("wf-button", {
        attrs: {
          loading: _vm.submitLoading,
          "button-list": _vm.buttonList,
          process: _vm.process,
          comment: _vm.comment,
        },
        on: {
          examine: _vm.handleExamine,
          "user-select": _vm.handleUserSelect,
          print: _vm.handlePrint,
          rollback: _vm.handleRollbackTask,
          terminate: _vm.handleTerminateProcess,
          withdraw: _vm.handleWithdrawTask,
        },
      }),
      _c("user-select", {
        ref: "user-select",
        attrs: {
          "check-type": _vm.checkType,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }