<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   avatar
                   :rows="8">
      <avue-affix id="avue-view"
                  :offset-top="114">
        <div class="header">
          <avue-title :value="process.processDefinitionName"></avue-title>
          <div v-if="process.status != 'todo'">
            主题：
            <avue-select v-model="theme"
                         size="mini"
                         :clearable="false"
                         :dic="themeList"></avue-select>
          </div>
        </div>
      </avue-affix>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadFirst`)"
                     name="first">
          <el-card shadow="never">
            <div id="printBody">
              <!-- 自定义表单区域 -->

              <el-scrollbar>
                <form-layout
                  ref="formLayout"
                  :column="option.column"
                  :dataForm="form"
                ></form-layout>
              </el-scrollbar>

              <!-- 自定义表单区域 -->
            </div>
          </el-card>
          <!-- 审批意见表单 -->
          <el-card shadow="never"
                   style="margin-top: 20px"
                   v-if="process.status == 'todo'">
            <wf-examine-form ref="examineForm"
                             :comment.sync="comment"
                             :process="process"
                             @user-select="handleUserSelect"></wf-examine-form>
          </el-card>
        </el-tab-pane>
        <!-- 流转信息 -->
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadSecond`)"
                     name="second">
          <el-card shadow="never"
                   style="margin-top: 5px;">
            <wf-flow :flow="flow"></wf-flow>
          </el-card>
        </el-tab-pane>
        <!-- 流程图 -->
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadThird`)"
                     name="third">
          <template v-if="activeName == 'third'">
            <el-card shadow="never"
                     style="margin-top: 5px;">
              <wf-design ref="bpmn"
                         style="height: 500px;"
                         :options="bpmnOption"></wf-design>
            </el-card>
          </template>
        </el-tab-pane>
      </el-tabs>
    </avue-skeleton>

    <!-- 底部按钮 -->
    <wf-button :loading="submitLoading"
               :button-list="buttonList"
               :process="process"
               :comment="comment"
               @examine="handleExamine"
               @user-select="handleUserSelect"
               @print="handlePrint"
               @rollback="handleRollbackTask"
               @terminate="handleTerminateProcess"
               @withdraw="handleWithdrawTask"></wf-button>
    <!-- 人员选择弹窗 -->
    <user-select ref="user-select"
                 :check-type="checkType"
                 :default-checked="defaultChecked"
                 @onConfirm="handleUserSelectConfirm"></user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from '@/views/plugin/workflow/process/components/examForm.vue'
import WfButton from '@/views/plugin/workflow/process/components/button.vue'
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue'
import userSelect from '@/views/plugin/workflow/process/components/user-select'

import exForm from '@/views/plugin/workflow/mixins/ex-form'
import theme from '@/views/plugin/workflow/mixins/theme'

export default {
  mixins: [exForm, theme],
  components: { userSelect, WfExamineForm, WfButton, WfFlow },
  watch: {
    '$route.query.p': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const { taskId, processInsId } = param
          if (taskId && processInsId) this.getDetail(taskId, processInsId)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: 'first',
      option: {
        column: [
          {
            label: '属性1',
            prop: 'attr1',
            disabled: true
          },
          {
            label: '属性2',
            prop: 'attr2',
            disabled: true
          },
          {
            label: '属性3',
            prop: 'attr3',
            disabled: true
          },
          {
            label: '属性4',
            prop: 'attr4',
            disabled: true
          }
        ]
      },
      defaults: {},
      form: {},
      // option: {}, // 表单配置
      vars: [], // 需要提交的字段
      submitLoading: false, // 提交时按钮loading
    }
  },
  methods: {
    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then(res => {
        const { process, form } = res
        const { variables, status } = process
        console.log("processDetail", res)
        let { taskForm } = form
        console.log("process", process)
        console.log("taskForm", taskForm)
        console.log("variables", variables)
        this.form = variables
        console.log("status", status) // todo待办 done已办

        // 自行处理相关逻辑

        this.waiting = false // 关闭骨架屏
      })
    },
    // 审核
    handleExamine(pass) {
      this.submitLoading = true
      this.$refs.formLayout.form.validate((valid, done) => {
        if (valid) {
          const variables = {}
          this.vars.forEach(v => {
            if (v != 'comment' && this.form[v]) variables[v] = this.form[v]
          })

          this.handleCompleteTask(pass, variables).then(() => {
            this.$message.success("处理成功")
            this.handleCloseTag('/plugin/workflow/process/todo')
          }).catch(() => {
            done()
            this.submitLoading = false
          })
        } else {
          done()
          this.submitLoading = false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}
::v-deep .bjs-powered-by {
  display: none;
}
</style>
